<!-- emailPreviewHtml -->
<template>
  <div class="emailPreviewHtml">
    <div class="center_2" style="padding-top:20px">
      <el-tabs type="border-card" v-model="checkTab" v-if="tabData.length > 0">
        <el-tab-pane v-for="(item, index) in tabData" :key="index" :label="item.text" :name="String(item.id)"></el-tab-pane>
      </el-tabs>
      <iframe v-if="showIframe" id="iframe" style="margin-top:20px;" :style="{ width: iframeWidth }" :src="pageUrl" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { getTemplateInfoLabel, getTemplateHtml5InfoAndType } from '@/api/emailPush'
export default {
  name: 'emailPreviewHtml',
  data() {
    return {
      tabData: [],
      height: '',
      checkTab: '',
      iframeWidth: '',
      showIframe: false
    }
  },
  props: {},
  components: {},
  computed: {
    pageUrl() {
      return (
        window.location.origin +
        '/das-api/vas/push/task/getTemplateHtml5InfoAndType?historyId=' +
        this.$route.query.taskId +
        '&pushHistoryTemplateId=' +
        this.$route.query.pushHistoryTemplateId +
        '&type=' +
        this.checkTab +
        '&nameSpace=' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '') +
        '/das-api'
      )
    }
  },
  mounted() {
    this.getTemplateInfoLabel()
  },
  methods: {
    setIframeHeight(id) {
      let _this = this
      try {
        var iframe = document.getElementById(id)
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', function() {
            _this.iframeWidth = iframe.contentWindow.document.documentElement.scrollWidth + 8 + 'px'
            iframe.height = iframe.contentWindow.document.documentElement.scrollHeight + 20
          })
          return
        } else {
          iframe.onload = function() {
            _this.iframeWidth = iframe.contentDocument.body.scrollWidth + 8 + 'px'
            iframe.height = iframe.contentDocument.body.scrollHeight + 20
          }
          return
        }
      } catch (e) {
        throw new Error('setIframeHeight Error')
      }
    },
    getTemplateHtml5InfoAndType() {
      let params = {
        historyId: this.$route.query.taskId,
        pushHistoryTemplateId: this.$route.query.pushHistoryTemplateId,
        type: this.checkTab,
        nameSpace: window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/das-api'
      }
      getTemplateHtml5InfoAndType(params).then((res) => {
        if (res.data && !res.data.status) {
          this.showIframe = true
          this.$nextTick(() => {
            this.setIframeHeight('iframe')
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getTemplateInfoLabel() {
      let params = {
        historyId: this.$route.query.taskId,
        pushHistoryTemplateId: this.$route.query.pushHistoryTemplateId
      }
      getTemplateInfoLabel(params).then((res) => {
        this.tabData = []
        if (res.data.status === 0) {
          let data = res.data.data
          for (let i in data) {
            let obj = {
              text: i,
              id: data[i]
            }
            this.tabData.push(obj)
          }
          if (this.tabData.length > 0) {
            this.checkTab = String(this.tabData[0].id)
          }
          this.getTemplateHtml5InfoAndType()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.emailPreviewHtml {
  .el-tabs {
    width: 810px;
    margin: auto;
    .el-tabs__content {
      display: none;
    }
  }
}
</style>
